import sulliWithHat from './sulliWithHat.png';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="fill">
          <div style={{ position: 'relative' }}>
            <div
              style={{ 
                display: 'flex',
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                width: '30%',
                height: '20%',
                margin: '17% 0px 0px 68%',
                color: '#444',
                fontSize: 'calc(10px + 1vw)'
              }}
              >
                G'day Mates!
            </div>
            <img src={sulliWithHat} alt="Logo" className='fill' />
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
